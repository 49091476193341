<template>
    <v-row>
      <v-col cols="12">
        <base-card>
          <v-row>
            <v-col cols="9" md="11"
              ><v-card-title
                >Cash Record<span>({{ dateString }})</span></v-card-title
              ></v-col
            >
            <v-col cols="3" md="1">
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-btn v-on="on" class="mt-3" icon>
                    <v-icon>mdi-calendar</v-icon>
                  </v-btn>
                </template>
                <v-date-picker v-model="date" range scrollable>
                  <v-spacer></v-spacer>
                  <v-btn
                    text
                    color="primary"
                    @click="$refs.dialog.save(date), getAllSettlement()"
                  >
                    {{ $t("salesSummaryPage.choose") }}
                  </v-btn>
                </v-date-picker>
              </v-dialog>
            </v-col>
            <v-col cols="5" md="3" class="ml-4">
              <v-select
                class="mt-3"
                v-model="selectBranch"
                :items="branchList"
                item-text="name"
                item-value="branch_id"
                @change="getAllSettlement()"
                outlined
                dense
              ></v-select
            ></v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <base-card>
                <div class="d-flex align-center justify-space-between pr-3">
                  <v-spacer></v-spacer>
                  <div v-if="salesList.length != 0">
                    <export-excel
                      :data="salesList"
                      :fields="json_fields"
                      :name="filename"
                    >
                      <v-tooltip left>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on"> mdi-download </v-icon>
                        </template>
                        <span
                          >Download Refund Report
                        </span>
                      </v-tooltip>
                    </export-excel>
                  </div>
                </div>
                <v-card-text>
                  <v-data-table
                    :loading="listLoading"
                    :headers="headers"
                    :items="salesList"
                    :page.sync="page"
                    :items-per-page="itemsPerPage"
                    @page-count="pageCount = $event"
                    item-key="refund_key"
                    hide-default-footer
                    :no-data-text="$t('dailyModifierSalesPage.noData')"
                  >
                  </v-data-table>
                  <div class="text-center py-2">
                    <v-pagination
                      v-model="page"
                      :length="pageCount"
                    ></v-pagination>
                  </div>
                </v-card-text>
              </base-card>
            </v-col>
          </v-row>
        </base-card>
      </v-col>
    </v-row>
  </template>
          <script>
  import { BASEURL } from "@/api/baseurl";
  import axios from "axios";
  
  import VueHtml2pdf from "vue-html2pdf";
  
  export default {
    name: "Cash-Record",
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: "Cash Record",
    },
    components: {
      VueHtml2pdf,
    },
    data() {
      return {
        json_fields: {
          "Branch": "branch_name",
          "Refund By": "refund_by",
          "Dining Option": "dining_name",
          "Total Amount": "final_amount",
          "Payment Method": "payment_method_sales",
          "Refund At": "created_at",
        },
        domain: BASEURL,
        company_id: "",
        date: [],
        modal: false,
        branchList: [],
        salesList: [],
        selectBranch: "",
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,
        listLoading: false,
      };
    },
    computed: {
      filename() {
        var moment = require("moment");
        return (
          "Refund Report_" +
          (this.date.length > 1
            ? this.date[0] != this.date[1]
              ? moment(this.date[1]).isBefore(this.date[0])
                ? this.date[1] + " until " + this.date[0]
                : this.date[0] + " until " + this.date[1]
              : this.date[0]
            : this.date[0]) +
          ".xls"
        );
      },
  
      dateString() {
        var moment = require("moment");
        return this.date.length > 1
          ? this.date[0] != this.date[1]
            ? moment(this.date[1]).isBefore(this.date[0])
              ? this.date[1] + "~" + this.date[0]
              : this.date[0] + "~" + this.date[1]
            : this.date[0]
          : this.date[0];
      },
  
      headers() {
        return [
          {
            text: "Branch",
            align: "start",
            sortable: false,
            value: "branch_name",
          },
  
          {
            text: "Refund By",
            align: "start",
            sortable: false,
            value: "refund_by",
          },
  
          {
            text: "Dining Option",
            align: "start",
            sortable: false,
            value: "dining_name",
          },
  
          {
            text: "Total Amount",
            align: "start",
            value: "final_amount",
            sortable: true,
          },
  
          {
            text: "Payment Method",
            align: "start",
            value: "payment_method_sales",
            sortable: false,
          },
  
          {
            text: "Refund At",
            align: "start",
            value: "created_at",
            sortable: true,
          },
        ];
      },
    },
    watch: {
      date() {
        this.salesList = [];
      },
      selectBranch() {
        this.salesList = [];
      },
    },
    created() {
      var CryptoJS = require("crypto-js");
      var x = localStorage.getItem("userInfo");
      // Decrypt
      var bytes = CryptoJS.AES.decrypt(x, "secret key 123");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      this.companyID = decryptedData.company_id;
      this.getCompanyBranch();
    },
    methods: {
      getAllSettlement() {
        this.listLoading = true;
        if (this.date.length <= 1) {
          const params = new URLSearchParams();
          params.append("getRefundList", "done");
          params.append("branch_id", this.selectBranch);
          params.append("month", this.date[0]);
  
          axios({
            method: "post",
            url: this.domain + "/report/index.php",
            data: params,
          })
            .then((response) => {
              console.log(response);
              if (response.data.status == "1") {
                this.salesList = response.data.report;
                this.listLoading = false;
              } else {
                this.salesList = [];
                this.listLoading = false;
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          if (this.date[0] == this.date[1]) {
            const params = new URLSearchParams();
            params.append("getRefundList", "done");
            params.append("branch_id", this.selectBranch);
            params.append("month", this.date[0]);
  
            axios({
              method: "post",
              url: this.domain + "/report/index.php",
              data: params,
            })
              .then((response) => {
                console.log(response);
  
                if (response.data.status == "1") {
                  this.salesList = response.data.report;
                  this.listLoading = false;
                } else {
                  this.salesList = [];
                  this.listLoading = false;
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            var moment = require("moment");
            var firstDate = "";
            var secondDate = "";
            if (moment(this.date[1]).isBefore(this.date[0])) {
              firstDate = this.date[1];
              secondDate = this.date[0];
            } else {
              firstDate = this.date[0];
              secondDate = this.date[1];
            }
  
            const params = new URLSearchParams();
            params.append("getRefundRangeList", "done");
            params.append("branch_id", this.selectBranch);
            params.append("first_date", firstDate);
            params.append("second_date", secondDate);
  
            axios({
              method: "post",
              url: this.domain + "/report/index.php",
              data: params,
            })
              .then((response) => {
                console.log(response);
  
                if (response.data.status == "1") {
                  this.salesList = response.data.report;
                  this.listLoading = false;
                } else {
                  this.salesList = [];
                  this.listLoading = false;
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        }
      },
  
      getCompanyBranch() {
        const params = new URLSearchParams();
        params.append("getCompanyBranch", "done");
        params.append("company_id", this.companyID);
  
        axios({
          method: "post",
          url: this.domain + "/branch/index.php",
          data: params,
        })
          .then((response) => {
            console.log(response);
            if (response.data.status == "1") {
              var moment = require("moment");
              this.branchList = response.data.branch;
              this.selectBranch = this.branchList[0].branch_id;
              this.date.push(moment().format("YYYY-MM-DD"));
              this.getAllSettlement();
            }
          })
          .catch((error) => {
            console.log(error);
          });
      },
    },
  };
  </script>
     
          
          
          